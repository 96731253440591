import { useState, useEffect, useMemo } from "react";

import Layout from "../layout/layout";
import MineLoader from "../components/mineLoader";
import Greetings from "../components/greetings";
import SpiritChoosen from '../components/themeChooser';
import Next from "../components/buttonNext";

function Theme() {
    const [screenLoading, setScreenLoading] = useState(false);

    useMemo(() => {
        // componentWillMount events
        setScreenLoading(true);
        setTimeout(() => {
        setScreenLoading(false);
        }, 1000);
    },[]);

    // useEffect(() => {
        
    // }, []);

    return ( 
        <Layout>
            {screenLoading ?
                <MineLoader />
                :
                <div className="flex-column justify-center">
                    <Greetings/>
                    
                    <SpiritChoosen/>
                    <Next text={"Next"} path={"/color"}/>
                </div>
            }
        </Layout>    
    )
}

export default Theme;