import { useDispatch, useSelector } from 'react-redux';
import { setMainTheme } from "../store/theme";
import arr from '../themes.json';
import color from '../store/color';

export default function SpiritChoosen() {

    const t = useSelector(state => state.theme);
    const s = useSelector(state => state.site);
    const dispatch = useDispatch();

    const active = {
        boxShadow: (s.mainSite === 'digitum')?"0px 0px 31.7px -3px rgba(0, 187, 169, 0.70)":"0px 0px 31.7px -3px rgba(255, 130, 6, 0.70)",
        border: (s.mainSite === 'digitum')?"1px solid #00BBA9":"1px solid #FF8206"
    }

    const activeText = {
        color: (s.mainSite === 'digitum')?"#00BBA9":"#FF8206",
        fontWeight: "700"
    }

    return (
        <section>
            <div className="wrapper">
                <h4 className='mb-32' style={{color: (s.mainSite === 'digitum')?"#00BBA9":"#FF8206"}}>
                Please, select your festive mood
                </h4>
                <div className="flex justify-space flex-wrap w-326 m-auto m-w-100p">
                    {
                        arr.theme.map((theme, index) => {
                            return(<div key={index} className='pointer mb-16' onClick={() => {dispatch(setMainTheme(theme.id))}}>
                                <div style={(theme.id === t.mainTheme) ? active: null} className='flex justify-center align-center w-92 h-92 bg-grey border-16 border-black m-w-82 m-h-82'>
                                    <img className='w-32 h-32' src={"./imgs/" + theme.path} alt='image'/>
                                </div>
                                <p className='mt-8 m-fs-12' style={(theme.id === t.mainTheme) ? activeText: null}>
                                    {theme.name}
                                </p>
                            </div>)
                        })
                    }
                </div>
            </div>
        </section>
    );
}