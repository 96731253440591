import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { setmdImages } from "../store/mdImages";
import { useDispatch, useSelector } from 'react-redux';
import Layout from "../layout/layout";
import HBall from "../components/hipnoBall";
import LoadLine from "../components/loadLine";

function Loading() {
    let navigate = useNavigate();
    const [screenLoading, setScreenLoading] = useState(false);
    const [text, setText] = useState('Creating holiday joy!');

    const theme = useSelector(state => state.theme);
    const color = useSelector(state => state.color);
    const site = useSelector(state => state.site);
    

    const dispatch = useDispatch();

    function fetchImages(){
        var json = {
            theme: theme.mainTheme,
            style: color.mainColor,
            site: site.mainSite,
            token: ''
        }
       
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },

            body: JSON.stringify(json)
        };
        setTimeout(() => {setText('Our AI-helper is working on your personalized surprise!')}, 7000);
        setTimeout(() => {setText('Winter wonders in progress!')}, 17 * 1000);
        setTimeout(() => {setText('Almost there - just a snowflake away!')}, 30 * 1000);
        
       fetch('https://2024.digitum.nl/AIImageMid', requestOptions).then(x=> x.json()).then(x=> {
        if (x.imgs[0].indexOf('adbot') === -1){
            dispatch(setmdImages(x.imgs));
            navigate("/result");
        }else{
            var arr = [];
            var link = site.mainSite == 'digitum' ? 'https://2024.digitum.nl/' : 'https://2024.factum-ua.com/';
            var theme = parseInt(theme.mainTheme);
            if (theme >= 8){
                theme = Math.floor(Math.random() * 8);
            }
            for(var i = 0; i < 4; i++){
                arr.push(link + 'def/'+ theme.toString() + '/'+color.mainColor + '_'+i.toString() + '.png')
            }
            dispatch(setmdImages(arr));
            navigate("/result");
        }
          
        });
    }
    useMemo(() => {
        // componentWillMount events
        setScreenLoading(true);
        setTimeout(() => {
        setScreenLoading(false);
        }, 1000);
    },[]);

    useEffect(() => {
      /*  setTimeout(() => {
            navigate("/result");
        }, 10000);*/
        fetchImages();
    }, []);
    
    return (
        <Layout>
            <div className="hBallContainer">
                <HBall/>
            </div>
            <p className="mt-40">
            {text}
            </p>
            <LoadLine/>
        </Layout>
    )
}

export default Loading;