import { useNavigate } from "react-router-dom";

const Back = ({path}) => {
    let navigate = useNavigate();

    return(
        <svg className="btn-2 pointer back-pointer" onClick={() => {navigate(path)}} width="12" height="23" viewBox="0 0 12 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4633 22.12L1.77 13.4267C0.743333 12.4 0.743333 10.72 1.77 9.69333L10.4633 1" stroke="#BDBDBD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export default Back;