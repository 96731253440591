import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: 'Friend'
}

const nameLogic = createSlice({
    name: 'nameChoose',
    initialState,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload
        }
    }
});

export const {setName} = nameLogic.actions;
export default nameLogic.reducer;