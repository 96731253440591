import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Layout from "../layout/layout";
import MineLoader from "../components/mineLoader";
import Next from "../components/buttonNext";
import { shareOnMobile } from "react-mobile-share";
import Repeat from "../components/buttonRepeat";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router";

import Snowfall from 'react-snowfall';

function Result() {
    let navigate = useNavigate();

    const [screenLoading, setScreenLoading] = useState(false);
    const mdImages = useSelector(state => state.mdImages);
    const [images, setImages] = useState(mdImages.mdImages);
    const d_name = useSelector(state => state.name.name);
    const s = useSelector(state => state.site);
    const step = useSelector(state => state.step.step);
    const [rStep, setRStep] = useState(step);
    function download(canvas, filename) {
        const data = canvas.toDataURL("image/png;base64");
        console.log(data);
        var a = document.createElement("a"); //Create <a>
        a.href = data; //Image Base64 Goes here
        a.download = "Christmas card "+d_name+" 2024.png"; //File name Here
        a.click(); //Downloaded file


    }
    function getBase64Image(canvas){
       var res =  html2canvas(document.getElementById('screen_shot_area'), {
            useCORS: true, //By passing this option in function Cross origin images will be rendered properly in the downloaded version of the PDF
            backgroundColor: window.getComputedStyle(document.body, null).getPropertyValue('background-color'),
            onrendered: function (canvas) {
                //your functions here
            }
        }).then((canvas) => {
           return canvas.toDataURL("image/png;base64");
        });
       // const data = canvas.toDataURL("image/png;base64");
        return res;
    }
    function createScreenshot() {
        html2canvas(document.getElementById('screen_shot_area'), {
            useCORS: true, //By passing this option in function Cross origin images will be rendered properly in the downloaded version of the PDF
            backgroundColor: window.getComputedStyle(document.body, null).getPropertyValue('background-color'),
            onrendered: function (canvas) {
                //your functions here
            }
        }).then((canvas) => {
            download(canvas, "asd");
        });
    }
    const dispatch = useDispatch();
    useMemo(() => {
        // componentWillMount events
        setScreenLoading(true);
        setTimeout(() => {
            setScreenLoading(false);
        }, 1000);
    }, []);
    function upStep() {
        if (rStep < 4) {
            const n_step = rStep + 1;
            setRStep(n_step);
        }

    }

    // useEffect(() => {

    // }, []);

    return (
        <Layout>
            {screenLoading ?
                <MineLoader />
                :
                <div className="flex-column justify-center">

                    <div className="result rslt-btns">
                        <img onClick={() => createScreenshot()} className="pointer absolute left w-32 h-32" src="./imgs/download.png" alt="icon" />
                        <img onClick={() =>  shareOnMobile({
                                text: "Truly unique New Year experience is awaiting you!",
                                url: s.mainSite === 'digitum' ? 'https://2024.digitum.nl' : 'https://2024.factum-ua.com',
                                title: s.mainSite.toUpperCase(),
                                images: [getBase64Image()]
                            }, createScreenshot())
                        } className="pointer absolute right w-32 h-32" src="./imgs/upload.png" alt="icon" />

                    </div>
                    <div style={{ padding: '2px 6px' }} id="screen_shot_area" className="result">
                        <div className="resultImageContainer">
                            <Snowfall speed={[1.0, 1.0]} wind={[-0.3, 0.3]} snowflakeCount={60} />
                            <img src={images[rStep]} alt="image" style={{ position: 'relative', zIndex: -2 }} />
                            {/* <img style={{position: 'absolute', marginLeft: '-363px', zIndex: -1}} src="imgs/Mask group.svg"/> */}
                        </div>
                        <h2 style={{ fontSize: d_name.length > 8 ? (32 - ((d_name.length - 8) + 3)).toString() + 'px' : '32px' }} className="result_name">Dear {d_name}</h2>
                        <p className="mb-40">
                            We're sprinkling good cheer and delight this season!
                            Spread some happiness around with your personalized snow globe!
                        </p>
                        <p className="mb-40">
                            Merry Christmas from {s.mainSite === 'digitum' ? "Digitum" : "Factum"}
                        </p>
                    </div>


                    <Next text={"Share Greetings with a Friend"} path={"/share"} />
                    <div style={{ display: 'flex', justifyContent: 'center' }} >
                        <div style={{ opacity: rStep >= 3 ? '0' : '1' }} onClick={() => upStep()} className="result-bottom"><img src={s.mainSite === 'digitum' ? "imgs/reapeat.png" : "imgs/repeat_factum.png"} /><p style={{ color: (s.mainSite === 'digitum') ? "#00BBA9" : "#FF8206" }}>Repeat</p></div>
                        <div style={{ width: '30px' }}></div>
                        <div onClick={() => navigate("/name")} className="result-bottom"><img src={s.mainSite === 'digitum' ? "imgs/restart.png" : "imgs/restart_factum.png"} /><p style={{ color: (s.mainSite === 'digitum') ? "#00BBA9" : "#FF8206" }}>Restart</p></div>
                    </div>
                </div>
            }
        </Layout>
    )
}

export default Result;