import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    step: 0
}

const stepLogic = createSlice({
    name: 'stepChoose',
    initialState,
    reducers: {
        setStep: (state, action) => {
            state.step = action.payload
        }
    }
});

export const {setStep} = stepLogic.actions;
export default stepLogic.reducer;