import { configureStore } from "@reduxjs/toolkit";
import theme from "./theme";
import color from "./color";
import createTry from "./createTry";
import site from "./site";
import mdImages from "./mdImages";
import name from "./name";
import step from "./step";

export const store = configureStore({
    reducer: {
        theme: theme,
        color: color,
        createTry: createTry,
        site: site,
        name: name,
        step: step,
        mdImages: mdImages
    }
});