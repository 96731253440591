import { useDispatch, useSelector } from 'react-redux';
import { setMainColor } from "../store/color";
import arr from '../colors.json'
import Back from './back';

export default function ColorChoosen() {

    const c = useSelector(state => state.color);
    const s = useSelector(state => state.site);
    const dispatch = useDispatch();

    const active = {
        boxShadow: (s.mainSite === 'digitum')?"0px 0px 31.7px -3px rgba(0, 187, 169, 0.70)":"0px 0px 31.7px -3px rgba(255, 130, 6, 0.70)",
        // border: "1px solid #FF8206",
        borderRadius: "100%"
    }

    const activeText = {
        color: (s.mainSite === 'digitum')?"#00BBA9":"#FF8206",
        fontWeight: "700"
    }

    return (
        <section className='c-chooser'>
            <div className="wrapper">
                <h4 className='mb-32' style={{color: (s.mainSite === 'digitum')?"#00BBA9":"#FF8206"}}>
                <Back path={"/theme"}/> Please, select color scheme:
                </h4>
                <div className="flex justify-around flex-wrap m-auto color-chooser">
                    {
                        arr.colors.filter(x=> x.id != (s.mainSite != 'digitum' ? 3 : 1)).map((color, index) => {
                            return(
                            <div key={index} className='pointer mb-16' onClick={() => {dispatch(setMainColor(color.id))}}>
                                <img className='w-130 h-130' style={(color.id === c.mainColor) ? active : null} src={"./imgs/" + color.path} alt='image'/>
                                <p className='mt-8' style={(color.id === c.mainColor) ? activeText : null}>
                                    {color.name}
                                </p>
                            </div>)
                        })
                    }
                </div>
            </div>
        </section>
    );
}