import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mdImages: ["https://2024.digitum.nl/static/media/uploads/c4zzuboj.0sa.png",
    "https://2024.digitum.nl/static/media/uploads/sjbteqz3.oqe.png",
    "https://2024.digitum.nl/static/media/uploads/qhxkecou.duj.png",
    "https://2024.digitum.nl/static/media/uploads/ehihnkax.hci.png"]
}

const mdImagesLogic = createSlice({
    name: 'mdImages',
    initialState,
    reducers: {
        setmdImages: (state, action) => {
            state.mdImages = action.payload
        }
    }
});

export const {setmdImages} = mdImagesLogic.actions;
export default mdImagesLogic.reducer;