import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mainColor: null
}

const colorLogic = createSlice({
    name: 'colorChoose',
    initialState,
    reducers: {
        setMainColor: (state, action) => {
            state.mainColor = action.payload
        }
    }
});

export const {setMainColor} = colorLogic.actions;
export default colorLogic.reducer;