import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Layout from "../layout/layout";
import MineLoader from "../components/mineLoader";
import { useNavigate } from "react-router-dom";
import { shareOnMobile } from "react-mobile-share";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Share() {
    const [name, setName] = useState("Friend");
    const [screenLoading, setScreenLoading] = useState(false);
    const s = useSelector(state => state.site);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const notify = () => toast.success('Link copied to clipboard!', {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    useMemo(() => {
        // componentWillMount events
        setScreenLoading(true);
        setTimeout(() => {
            setScreenLoading(false);
        }, 1000);
    }, []);

    // useEffect(() => {

    // }, []);
    function copyToClipboard() {
        var text = s.mainSite === 'digitum' ? 'https://2024.digitum.nl' : 'https://2024.factum-ua.com';
        navigator.clipboard.writeText(text);
        notify();
    }
    return (
        <Layout>
            {screenLoading ?
                <MineLoader />
                :
                <div className="flex-column justify-center m-mt-70">


                    <div style={{ padding: '2px 6px', width: '100%' }} className="result">


                        <p  style={{ color: (s.mainSite === 'digitum') ? "#00BBA9" : "#FF8206" }} className="mb-40">
                            Share the link below<br style={{display: 'block'}}/>to spread a New Year vibe<br style={{display: 'block'}}/>among your family and friends!
                        </p>
                        <p onClick={() => copyToClipboard()} className="fake-input">{s.mainSite === 'digitum' ? 'https://2024.digitum.nl' : 'https://2024.factum-ua.com'}</p>
                        <p onClick={() =>
                            shareOnMobile({
                                text: "Truly unique New Year experience is awaiting you!",
                                url: s.mainSite === 'digitum' ? 'https://2024.digitum.nl' : 'https://2024.factum-ua.com',
                                title: s.mainSite.toUpperCase(),
                            }, copyToClipboard())
                        } className="share-button" style={{width: 'auto', backgroundColor: (s.mainSite === 'digitum') ? "#00BBA9" : "#FF8206" }}><img style={{marginRight: '8px'}} src="imgs/copy.png"/>Copy link</p>
                   <div style={{width: '100%', marginTop: '32px', display: 'flex', justifyContent: 'center'}} onClick={() =>   navigate("/name")} className="result-bottom"><img src={s.mainSite === 'digitum' ? "imgs/restart.png" : "imgs/restart_factum.png"}/><p style={{color: (s.mainSite === 'digitum')?"#00BBA9":"#FF8206"}}>Restart</p></div>
                    
                    </div>
                    
                    <ToastContainer
                        position="bottom-center"
                        autoClose={1500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />


                </div>
            }
        </Layout>
    )
}

export default Share;