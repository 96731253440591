import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "../layout/layout";

function Main() {
  let navigate = useNavigate();
  
  useEffect(() => {
    navigate("/name");
  });
}

export default Main;