import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Layout from "../layout/layout";
import { useNavigate } from "react-router-dom";
import { setName } from "../store/name";
import MineLoader from "../components/mineLoader";
import Next from "../components/buttonNext";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function Name() {
    const [name, _setName] = useState("Friend");
    const [screenLoading, setScreenLoading] = useState(false);
    const [firstClick, setFirstClick] = useState(true);

    let navigate = useNavigate();

    const s = useSelector(state => state.site);
    const dispatch = useDispatch();
    const notify = () => toast.error('Please type the name', {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    useMemo(() => {
        // componentWillMount events
        setScreenLoading(true);
        setTimeout(() => {
            setScreenLoading(false);
        }, 1000);
    }, []);

    function next() {
        if (name.length > 0) {
            dispatch(setName(name));
            navigate("/theme");
        } else {
            notify();
        }
    }
    function clickInput(){
        
        if (firstClick){
            _setName('');
            setFirstClick(false);
        }
    }
    return (
        <Layout>
            {screenLoading ?
                <MineLoader />
                :
                <div className="flex-column justify-center m-mt-70">


                    <div style={{ padding: '2px 6px', width: '100%' }} className="result">


                        <p style={{ color: '#BDBDBD', fontWeight: '700' }} className="mb-40">
                            Truly unique<br style={{display: 'block'}}/>festive experience<br style={{display: 'block'}}/>is awaiting you!
                        </p>
                        <p style={{ color: (s.mainSite === 'digitum') ? "#00BBA9" : "#FF8206" }} className="mb-40">
                            Please, type your name :
                        </p>
                       <div> <input onClick={() => clickInput()} value={name} onInput={(e) => _setName(e.target.value)} className="name-input" placeholder="Name" /></div>
                        <p onClick={() => next()} className="share-button" style={{width: 'calc(100% - 64px)', backgroundColor: (s.mainSite === 'digitum') ? "#00BBA9" : "#FF8206" }}>Submit</p>
                    </div>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={1500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />


                </div>
            }
        </Layout>
    )
}

export default Name;