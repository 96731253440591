// libs
import { Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// store
import { store } from './store/index.js';

// pages
import Main from "./pages/Main";
import Theme from "./pages/Theme";
import Color from "./pages/Color";
import Loading from "./pages/Loading";
import Result from "./pages/Result";
import Share from "./pages/Share";
import Name from "./pages/Name";



// style
import './assets/styles/mainStyle.css';

function App() {
  return (
    <>
      <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Main />} />
              <Route path='/name' element={<Name />} />
              <Route path='/theme' element={<Theme />} />
              <Route path='/color' element={<Color />} />
              <Route path='/loading' element={<Loading />} />
              <Route path='/result' element={<Result />} />
              <Route path='/share' element={<Share />} />
              <Route path="*" element={<Main />} />
            </Routes>
          </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
