import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mainTheme: 8
}

const themeLogic = createSlice({
    name: 'themeChoose',
    initialState,
    reducers: {
        setMainTheme: (state, action) => {
            state.mainTheme = action.payload
        }
    }
});

export const {setMainTheme} = themeLogic.actions;
export default themeLogic.reducer;