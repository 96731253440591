import React from 'react';

import Footer from '../components/footer'

const Layout =({children}) =>{
    return(
        <>
            <main>
                <div className='wrapper'>
                    {children}
                </div>
            </main>
            <Footer/>
        </>
    )
}

export default Layout;
