import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useState } from "react";

const Next = ({ path, text }) => {
    const [alertText, setAlertText] = useState("");
    let navigate = useNavigate();

    const t = useSelector(state => state.theme);
    const c = useSelector(state => state.color);
    const s = useSelector(state => state.site);

    function next_step() {
        console.log('a');
        setAlertText("");
        navigate(path);

    }

    return (
        <>
            <button className="btn-1 m-auto pointer" style={{ backgroundColor: (s.mainSite === 'digitum') ? "#00BBA9" : "#FF8206" }} onClick={() => {
                if (window.location.pathname === "/theme") {
                    (t.mainTheme === null) ? setAlertText("Choose your guiding spirit") : next_step();
                }
                if (window.location.pathname === "/color") {
                    (c.mainColor === null) ? setAlertText("Please, select color scheme") : next_step();
                }else{
                    next_step();
                }
            }} >
                <p>{text}</p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008" stroke="#030303" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
            <p className="mt-16 t-color-alert">
                {alertText}
            </p>
        </>
    );
}

export default Next;