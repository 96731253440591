import { useSelector } from 'react-redux';

export default function LoadLine() {
    const s = useSelector(state => state.site);

    return (
        <div className='mt-58 mb-58'>
            <p style={{color: (s.mainSite === 'digitum')?"#00BBA9":"#FF8206"}}>
                Generating...
            </p>
            <div className='containForAnimation' style={{border: (s.mainSite === 'digitum') ? "2px solid #00BBA9" : "2px solid #FF8206"}}>
                <svg id='line' width="170" height="16" viewBox="0 0 170 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_219_64634" Style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="166" height="16">
                    <path d="M3.73703 1H9.5963C9.93065 1 10.2429 1.1671 10.4283 1.4453L14.4284 7.4453C14.6523 7.7812 14.6523 8.2188 14.4283 8.5547L10.4283 14.5547C10.2429 14.8329 9.93065 15 9.5963 15H3.73704C2.93834 15 2.46195 14.1099 2.90498 13.4453L5.42578 9.6641C6.09758 8.6564 6.09758 7.3436 5.42578 6.3359L2.90498 2.5547C2.46195 1.89015 2.93834 1 3.73703 1Z" stroke={(s.mainSite === 'digitum')?"#00BBA9":"#FF8206"} stroke-width="2"/>
                    <path d="M22.4048 1H28.264C28.5984 1 28.9106 1.1671 29.0961 1.4453L33.0961 7.4453C33.32 7.7812 33.32 8.2188 33.0961 8.5547L29.0961 14.5547C28.9106 14.8329 28.5984 15 28.264 15H22.4048C21.6061 15 21.1297 14.1099 21.5727 13.4453L24.0935 9.6641C24.7653 8.6564 24.7653 7.3436 24.0935 6.3359L21.5727 2.5547C21.1297 1.89015 21.6061 1 22.4048 1Z" stroke={(s.mainSite === 'digitum')?"#00BBA9":"#FF8206"} stroke-width="2"/>
                    <path d="M41.0693 1H46.9286C47.2629 1 47.5752 1.1671 47.7606 1.4453L51.7606 7.4453C51.9846 7.7812 51.9846 8.2188 51.7606 8.5547L47.7606 14.5547C47.5752 14.8329 47.2629 15 46.9286 15H41.0693C40.2706 15 39.7942 14.1099 40.2373 13.4453L42.7581 9.6641C43.4299 8.6564 43.4299 7.3436 42.7581 6.3359L40.2373 2.5547C39.7942 1.89015 40.2706 1 41.0693 1Z" stroke={(s.mainSite === 'digitum')?"#00BBA9":"#FF8206"} stroke-width="2"/>
                    <path d="M59.737 1H65.5963C65.9307 1 66.2429 1.1671 66.4283 1.4453L70.4284 7.4453C70.6523 7.7812 70.6523 8.2188 70.4283 8.5547L66.4283 14.5547C66.2429 14.8329 65.9307 15 65.5963 15H59.737C58.9383 15 58.4619 14.1099 58.905 13.4453L61.4258 9.6641C62.0976 8.6564 62.0976 7.3436 61.4258 6.3359L58.905 2.5547C58.4619 1.89015 58.9383 1 59.737 1Z" stroke={(s.mainSite === 'digitum')?"#00BBA9":"#FF8206"} stroke-width="2"/>
                    <path d="M78.4048 1H84.264C84.5984 1 84.9106 1.1671 85.0961 1.4453L89.0961 7.4453C89.32 7.7812 89.32 8.2188 89.0961 8.5547L85.0961 14.5547C84.9106 14.8329 84.5984 15 84.264 15H78.4048C77.6061 15 77.1297 14.1099 77.5727 13.4453L80.0935 9.6641C80.7653 8.6564 80.7653 7.3436 80.0935 6.3359L77.5727 2.5547C77.1297 1.89015 77.6061 1 78.4048 1Z" stroke={(s.mainSite === 'digitum')?"#00BBA9":"#FF8206"} stroke-width="2"/>
                    <path d="M97.0693 1H102.929C103.263 1 103.575 1.1671 103.761 1.4453L107.761 7.4453C107.985 7.7812 107.985 8.2188 107.761 8.5547L103.761 14.5547C103.575 14.8329 103.263 15 102.929 15H97.0693C96.2706 15 95.7942 14.1099 96.2373 13.4453L98.7581 9.6641C99.4299 8.6564 99.4299 7.3436 98.7581 6.3359L96.2373 2.5547C95.7942 1.89015 96.2706 1 97.0693 1Z" stroke={(s.mainSite === 'digitum')?"#00BBA9":"#FF8206"} stroke-width="2"/>
                    <path d="M115.737 1H121.596C121.931 1 122.243 1.1671 122.428 1.4453L126.428 7.4453C126.652 7.7812 126.652 8.2188 126.428 8.5547L122.428 14.5547C122.243 14.8329 121.931 15 121.596 15H115.737C114.938 15 114.462 14.1099 114.905 13.4453L117.426 9.6641C118.098 8.6564 118.098 7.3436 117.426 6.3359L114.905 2.5547C114.462 1.89015 114.938 1 115.737 1Z" stroke={(s.mainSite === 'digitum')?"#00BBA9":"#FF8206"} stroke-width="2"/>
                    <path d="M134.405 1H140.264C140.598 1 140.911 1.1671 141.096 1.4453L145.096 7.4453C145.32 7.7812 145.32 8.2188 145.096 8.5547L141.096 14.5547C140.911 14.8329 140.598 15 140.264 15H134.405C133.606 15 133.13 14.1099 133.573 13.4453L136.094 9.6641C136.765 8.6564 136.765 7.3436 136.094 6.3359L133.573 2.5547C133.13 1.89015 133.606 1 134.405 1Z" stroke={(s.mainSite === 'digitum')?"#00BBA9":"#FF8206"} stroke-width="2"/>
                    <path d="M153.069 1H158.929C159.263 1 159.575 1.1671 159.761 1.4453L163.761 7.4453C163.985 7.7812 163.985 8.2188 163.761 8.5547L159.761 14.5547C159.575 14.8329 159.263 15 158.929 15H153.069C152.271 15 151.794 14.1099 152.237 13.4453L154.758 9.6641C155.43 8.6564 155.43 7.3436 154.758 6.3359L152.237 2.5547C151.794 1.89015 152.271 1 153.069 1Z" stroke={(s.mainSite === 'digitum')?"#00BBA9":"#FF8206"} stroke-width="2"/>
                    </mask>
                    <g mask="url(#mask0_219_64634)">
                    <rect x="54" width="258" height="16" rx="8" fill={(s.mainSite === 'digitum')?"#00BBA9":"#FF8206"}/>
                    </g>
                </svg>
            </div>
        </div>
    )
}