import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    countTry: null
}

const tryLogic = createSlice({
    name: 'tryChoose',
    initialState,
    reducers: {
        setMainTry: (state, action) => {
            state.countTry = action.payload
        }
    }
});

export const {setMainTry} = tryLogic.actions;
export default tryLogic.reducer;