import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

export default function Greetings() {
   

    const s = useSelector(state => state.site);
    const d_name = useSelector(state => state.name.name);
    useEffect(() => {
        // setName();
    }, []);

    const factumStyle = {
        textShadow: (s.mainSite === 'digitum') ? "0 0 2px #00BBA9" : "0 0 2px #FF8206",
        position: "relative"
    }

    const factumDecorText = {
        width: "380px",
        height: "20px",
        borderRadius: "380px",
        background: (s.mainSite === 'digitum') ? "rgba(0, 187, 169, 0.30)" : "rgba(255, 130, 6, 0.30)",
        filter: "blur(12px)",
        position: "absolute",
        top: "2px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: "-1"
    }

    return (
        <section>
            <div className="wrapper m-padding-0">
                <h1 className="mb-40 m-mb-16" style={factumStyle}>DEAR {d_name}!<div style={factumDecorText}></div></h1>
            </div>
        </section>
    );
}