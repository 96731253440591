import { useState, useEffect, useMemo } from "react";

import Layout from "../layout/layout";
import MineLoader from "../components/mineLoader";
import Next from "../components/buttonNext";
import ColorChoosen from "../components/colorChooser";
import Back from "../components/back";

function Color() {
    const [screenLoading, setScreenLoading] = useState(false);

    useMemo(() => {
        // componentWillMount events
        setScreenLoading(true);
        setTimeout(() => {
        setScreenLoading(false);
        }, 1000);
    },[]);

    // useEffect(() => {
        
    // }, []);

    return (
        <Layout>
            {screenLoading ?
                <MineLoader />
                :
                <div className="flex-column justify-center">
                    
                    <ColorChoosen/>
                    <Next text={"Next"} path={"/loading"}/>
                </div>
            }
        </Layout> 
    )
}

export default Color;